import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/shou-kuan-mx/list',
        method: 'post',
        data
    })
}

//销售统计列表（经营）
export function getXiaoShouTjList(data) {
    return request({
        url: '/shou-kuan-mx/xiao-shou-tong-ji',
        method: 'post',
        data
    })
}

//销售统计列表（门店）
export function getMdXiaoShouTjList(data) {
    return request({
        url: '/shou-kuan-mx/md-xiao-shou-tong-ji',
        method: 'post',
        data
    })
}

//销售报表列表
export function getXiaoShouBaoBiaoList(data) {
    return request({
        url: '/shou-kuan-mx/get-xiao-shou-bao-biao',
        method: 'post',
        data
    })
}


//获取当前门店收款明细统计数据
export function getShouKuanMXTongJi(data) {
    return request({
        url: '/shou-kuan-mx/get-shou-kuan-mx-tj',
        method: 'post',
        data
    })
}


//获取门店收款明细统计数据列表
export function getMdShouKuanMXTongJi() {
    return request({
        url: '/shou-kuan-mx/get-md-shou-kuan-mx-tj',
        method: 'get',
    })
}
//添加保存
export function add(data) {
    return request({
        url: '/shou-kuan-mx/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/shou-kuan-mx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/shou-kuan-mx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/shou-kuan-mx/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/shou-kuan-mx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/shou-kuan-mx/delete-batch',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'shou-kuan-mx-list',
    component: () => import('@/view/jygl/skmx/ShouKuanMXList'),
    name: 'ShouKuanMXList',
    meta: {title: '收款明细', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"收款明细基础列表","methodUrl":"/shou-kuan-mx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"收款明细添加保存","methodUrl":"/shou-kuan-mx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"收款明细获取编辑数据","methodUrl":"/shou-kuan-mx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"收款明细获取详情数据","methodUrl":"/shou-kuan-mx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"收款明细编辑保存","methodUrl":"/shou-kuan-mx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"收款明细删除","methodUrl":"/shou-kuan-mx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"收款明细批量删除","methodUrl":"/shou-kuan-mx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
