<template>
    <el-drawer :modal-append-to-body="false" :title="title" :visible.sync="detailVisible" @open="open" size="40%">
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="loading" :data="tableData" :height="tableHeight" border>
            <el-table-column width="150" prop="shangPinMC" label="名称"/>
            <el-table-column width="150" label="价格">
                <template slot-scope="scope">
                    <div v-if="scope.row.huiYuanYouHuiJia">
                        单价：￥{{scope.row.huiYuanYouHuiJia}}+{{scope.row.huiYuanYouHuiJiaJF}}积分
                    </div>
                    <div v-else>单价：￥{{scope.row.yuanDanJia}}</div>
                    <div>原价：￥{{scope.row.yuanDanJia}}</div>
                </template>
            </el-table-column>
            <el-table-column width="150" label="优惠信息">
                <template slot-scope="scope">
                    <span v-if="scope.row.huiYuanZheKou">会员折扣：￥{{scope.row.huiYuanZheKouJinE}}【{{scope.row.huiYuanZheKou}}】</span>

                    <span v-else>无</span>
                </template>
            </el-table-column>
            <el-table-column width="100" prop="shuLiang" label="数量"/>
            <el-table-column width="180" label="小计">
                <template slot-scope="scope">
                    <span v-if="scope.row.shiShouZongJia && scope.row.shiYongJiFen">￥{{Math.abs(scope.row.shiShouZongJia)}}+{{scope.row.shiYongJiFen}}积分</span>
                    <span v-else-if="scope.row.shiShouZongJia && !scope.row.shiYongJiFen">￥{{Math.abs(scope.row.shiShouZongJia)}}</span>
                    <span v-else>{{scope.row.shiYongJiFen}}积分</span>
                </template>
            </el-table-column>
        </el-table>
        <el-row class="el-row-class" type="flex" justify="space-between">
            <span class="col-left">合计：</span>
            <span class="col-right">￥{{Math.abs(this.shouKuanMX.zhiFuJinE)}}</span>
        </el-row>
        <el-row class="el-row-class" v-if="this.shouKuanMX.shiYongJiFen" type="flex" justify="space-between">
            <span class="col-left">使用积分：</span>
            <span class="col-right">￥{{this.shouKuanMX.shiYongJiFen}}</span>
        </el-row>
        <el-row class="el-row-class" v-if="this.shouKuanMX.gouMaiJiFen" type="flex" justify="space-between">
            <span class="col-left">购买积分：</span>
            <span class="col-right">￥{{this.shouKuanMX.gouMaiJiFen}}</span>
        </el-row>
        <el-row class="el-row-class" v-if="hyzkze" type="flex" justify="space-between">
            <span class="col-left">会员折扣：</span>
            <span class="col-right">￥{{this.huiYuanZheKouZE}}</span>
        </el-row>
        <el-row class="el-row-class" v-if="this.shouKuanMX.huiYuanHao" type="flex" justify="space-between">
            <span class="col-left">会员姓名：</span>
            <span class="col-right">{{this.shouKuanMX.huiYuanMC}}</span>
        </el-row>
        <el-row class="el-row-class" v-if="this.shouKuanMX.huiYuanHao" type="flex" justify="space-between">
            <span class="col-left">会员手机：</span>
            <span class="col-right">{{this.shouKuanMX.huiYuanShouJiHao}}</span>
        </el-row>
        <el-row class="el-row-class" type="flex" justify="space-between">
            <span class="col-left">应收：</span>
            <span class="col-right">￥{{Math.abs(this.shouKuanMX.zhiFuJinE)}}</span>
        </el-row>
        <div class="shi-fu">实付：<span class="shi-fu-color">￥{{Math.abs(this.shouKuanMX.zhiFuJinE)}}</span></div>
    </el-drawer>
</template>

<script>
    import XTableBase from "@/components/x/XTableBase";
    import * as service from "@/service/jygl/ShangPinXiaoShouMX";

    export default {
        mixins: [XTableBase],
        beforeCreate() {

        },
        data() {
            this.refreshService = service.getListByParentId;
            this.service = service;
            return {
                title: "订单详情",
                detailVisible: false,
                tableHeight: 500,
                loading: false,
                tableData: [],
                shouKuanMX: "",
                huiYuanZheKouZE: 0.0,

            }
        },
        computed: {
            hyzkze() {
                return this.huiYuanZheKouZE > 0.0;
            },
        },
        methods: {
            open() {
                this.refresh()
                console.log("这是传递过来的数据：", this.shouKuanMX)
            },
            getDingDanDetail() {

            },
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(this.shouKuanMX.id).then((response) => {
                    this.tableData = response.data
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
        }
    }
</script>

<style scoped>
    .col-left {
        text-align: left;
        font-size: 17px;
        color: #606266;
        margin-left: 10px;
    }

    .col-right {
        text-align: right;
        font-size: 17px;
        color: #606266;
        margin-right: 10px;
    }

    .shi-fu {
        text-align: right;
        font-size: 20px;
    }

    .shi-fu-color {
        font-size: 20px;
        color: rgb(221, 97, 97);
        margin-right: 10px;
    }

    .el-row-class {
        margin-bottom: 10px;
    }
</style>
